import { computed } from 'vue'

import type { T3ButtonProps } from '~shared/types'

const useButton = (props: T3ButtonProps) => {
  return computed(() => {
    if (!props.buttonTitle) return {}

    return {
      content: props.buttonTitle,
      variant: props.buttonColor,
      size: props.buttonSize,
      noWrap: props.buttonWrap,
      link: props.buttonLink,
      isArrow: props.arrowButton
    }
  })
}

export default useButton
